import { pageUp } from "@context/homePages"

export const NextButton = () => {
    return (
        <span className="label slot" onClick={() => pageUp()}> Next</span>
    )
}

export const PrevButton = () => {
    return (
        <span className="label slot" onClick={() => pageUp()}> Prev</span>
    )
}